import { gql } from '@apollo/client'

export const CREATE_GUILD_STRIPE_CHECKOUT = gql`
  mutation createGuildStripeCheckout($input: GuildStripeCheckoutInput) {
    createGuildStripeCheckout(input: $input) {
      amountTotal
      clientSecret
      currency
      sessionId
    }
  }
`
