import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { EmbeddedStripeCheckoutSessionSkeleton } from './EmbeddedStripeCheckoutSessionSkeleton'

interface EmbeddedStripeCheckoutSessionProps {
  clientSecret?: string | null
  loading?: boolean
  onComplete?: () => void
}

const stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY || '')

export const EmbeddedStripeCheckoutSession = ({
  clientSecret,
  loading,
  onComplete,
}: EmbeddedStripeCheckoutSessionProps) => {
  if (!loading && clientSecret) {
    return (
      <EmbeddedCheckoutProvider
        key={clientSecret}
        stripe={stripe}
        options={{
          clientSecret,
          onComplete,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    )
  }

  return <EmbeddedStripeCheckoutSessionSkeleton />
}
