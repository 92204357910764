import { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { useRouter } from 'next/router'
import { ChevronLeftIcon } from '@/atoms/Icons/ChevronLeftIcon'
import { InternalLink } from '@/atoms/InternalLink'
import { paths } from '@/constants/paths'
import { EmbeddedStripeCheckoutSession } from '@/molecules/EmbeddedStripeCheckoutSession'
import { useGuildStripeCheckoutSession } from '@/services/GuildStripeCheckoutSessionService'
import { Offer } from '@/services/PifService'
import { getPriceForCurrency } from '@/services/PriceFormatter'
import { useUser } from '@/services/UserService'
import { MoneyCurrencyCode } from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'
import { useSafeTrack } from '@/utils/analytics'
import { getSessionProperty } from '@/utils/analytics/SessionAnalytics'
import { getStickyAttribution } from '@/utils/analytics/StickyAttribution'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { getStringFromLocalStorage, removeFromLocalStorage } from '@/utils/local-storage'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { getUserGuildStatus, GuildStatus } from '@/utils/users/usersServer'

interface Props {
  email: string
  offer: Offer
}

export const GuildEmbeddedStripeCheckoutSession: ReactFCC<Props> = ({ email, offer }) => {
  const { t } = useTranslate('guild')
  const { back, query, push } = useRouter()
  const { user } = useUser()
  const track = useSafeTrack()
  const { trackOrderCompleted } = useEcommerceEvents()
  const apolloClient = useApolloClient()
  const [onCompleteLoading, setOnCompleteLoading] = useState(false)
  const { campaign, medium, term, content, source, pageSlug, projectSlugs } = getAttribution()

  // This is necessary because the tool we use to manage redemption codes in Shopify adds variant information to the end of the code and we have no control over how it works,
  // so we need to remove it here for the promo code to properly work with Stripe.
  const cleanShopifyCode = getItemOrFirstEntry(query?.codes)?.split(' ')?.[0] ?? undefined

  const promoCode = getItemOrFirstEntry(query?.promoCode) ?? cleanShopifyCode ?? undefined

  const { loading, clientSecret, currency, amountTotal, sessionId } = useGuildStripeCheckoutSession({
    offerId: offer.id,
    offerName: offer.name,
    email,
    promoCode,
    campaign,
    medium,
    term,
    content,
    source,
    pageSlug,
    projectSlugs,
  })

  const price = getPriceForCurrency(offer.price, offer.currency)

  const checkAndUpdateUserGuildStatus = async (
    timeDifference: number,
    oneMinute: number,
    userUuid: string | undefined | null,
    getUserGuildStatus: (uuid: string | null) => Promise<GuildStatus | null>,
  ): Promise<boolean> => {
    if (timeDifference < oneMinute) {
      if (userUuid) {
        await getUserGuildStatus(userUuid)
      }
      return true
    }
    return false
  }

  const onComplete = useCallback(async () => {
    if (loading || onCompleteLoading) return

    const checkoutInitiated = sessionStorage.getItem('checkoutInitiated')
    if (checkoutInitiated) {
      const { date } = JSON.parse(checkoutInitiated)
      const now = new Date()
      const initiatedDate = new Date(date)
      const timeDifference = now.getTime() - initiatedDate.getTime()
      const oneMinute = 60 * 1000

      if (await checkAndUpdateUserGuildStatus(timeDifference, oneMinute, user?.uuid, getUserGuildStatus)) {
        return
      }
    }

    setOnCompleteLoading(true)

    try {
      const newCheckoutInitiated = {
        date: new Date().toISOString(),
      }
      sessionStorage.setItem('checkoutInitiated', JSON.stringify(newCheckoutInitiated))

      const total = amountTotal ? getPriceForCurrency(amountTotal, currency || 'USD') : 0

      const sharerUuid = getStringFromLocalStorage('sharer_uuid') ?? null

      await Promise.allSettled([
        new Promise((resolve) => {
          track(
            'Guild Signup Successful',
            {
              offer: JSON.stringify(offer),
              promoCode,
              email,
              order_id: sessionId, // TODO: i can't see an easy way to get the real order id atm, but kylo said this would be helpful
              checkout_id: sessionId,
              total,
              revenue: price, // without taxes, technically this could be a different currency but not really?
              value: price, // without taxes, technically this could be a different currency but not really?
              currency: currency || 'USD',
              referring_user_id: sharerUuid,
              subscription_metadata: JSON.stringify({
                campaign,
                medium,
                term,
                content,
                source,
                pageSlug,
                projectSlugs,
              }),
            },
            null,
            resolve,
          )
        }),
        new Promise((resolve) => {
          trackOrderCompleted(
            {
              funnel: 'guild',
              currency: (currency as MoneyCurrencyCode) || 'USD',
              email,
              order_id: sessionId || '',
              total,
              checkout_id: sessionId ?? null,
              coupon: promoCode,
              referring_user_id: sharerUuid,
              subscription: true,
              subtotal: price, // without taxes, technically this could be a different currency but not really?
              projectSlug: projectSlugs ?? null,
              products: [
                {
                  category: 'guild',
                  name: offer.name,
                  price,
                  currency: (currency as MoneyCurrencyCode) || 'USD',
                  product_id: offer.id,
                  quantity: 1,
                  sku: offer.id,
                },
              ],
            },
            null,
            resolve,
          )
        }),
      ])

      await apolloClient.resetStore()
      await push(paths.guild.checkoutSuccess)
    } finally {
      if (getStringFromLocalStorage('sharer_uuid')) {
        removeFromLocalStorage('sharer_uuid')
      }
      setOnCompleteLoading(false)
    }
  }, [
    amountTotal,
    apolloClient,
    campaign,
    content,
    currency,
    email,
    loading,
    medium,
    offer,
    onCompleteLoading,
    pageSlug,
    price,
    projectSlugs,
    promoCode,
    push,
    sessionId,
    source,
    term,
    track,
    trackOrderCompleted,
    user?.uuid,
  ])

  return (
    <div className="min-h-560 w-full max-w-[450px] lg:max-w-[1000px]">
      <div
        onClick={() => {
          if (loading || onCompleteLoading) return
          return track('Guild Checkout Cancelled', { offer: JSON.stringify(offer) })
        }}
        className="mb-6 ml-8 lg:ml-16"
      >
        <InternalLink
          href={`#`}
          onClick={() => {
            if (loading || onCompleteLoading) return
            return back()
          }}
          className="flex w-fit items-center text-sm font-bold "
        >
          <ChevronLeftIcon color="black" className="mr-2" />
          <Translate t={t} i18nKey="goBackAPage">
            Back
          </Translate>
        </InternalLink>
      </div>
      <EmbeddedStripeCheckoutSession
        loading={loading || onCompleteLoading}
        clientSecret={clientSecret}
        onComplete={onComplete}
      />
    </div>
  )
}

interface AttributionResult {
  campaign?: string
  source?: string
  content?: string
  medium?: string
  term?: string
  pageSlug?: string | null
  projectSlugs?: string | null
}

export function getAttribution(): AttributionResult {
  const stickyAttributionUtms = getStickyAttribution('guild-attribution')
  const sessionUtms = getSessionProperty<SessionUtms>('originalUtms', {})
  const stickyAnalytics = stickyAttributionUtms?.analytics
  let utms = {}

  if (sessionUtms) {
    utms = {
      campaign: sessionUtms.utm_campaign,
      medium: sessionUtms.utm_medium,
      source: sessionUtms.utm_source,
      term: sessionUtms.utm_term,
      content: sessionUtms.utm_content,
    }
  } else if (stickyAnalytics) {
    utms = {
      ...stickyAnalytics,
    }
  }

  return {
    ...utms,
    pageSlug: getSessionProperty('pageSlug', null),
    projectSlugs: getSessionProperty('projectSlugs', null),
  }
}

type SessionUtms = {
  utm_campaign?: string
  utm_source?: string
  utm_medium?: string
  utm_term?: string
  utm_content?: string
}
