import { useEffect, useMemo, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { CreateGuildStripeCheckoutMutation, MoneyCurrencyCode } from '@/types/codegen-federation'
import { useSafeTrack } from '@/utils/analytics'
import { useEcommerceEvents } from '@/utils/analytics/ecommerce-events'
import { hasAuthSessionCookie } from '@/utils/auth-utils'
import { getPriceForCurrency } from '../PriceFormatter'
import { CREATE_GUILD_STRIPE_CHECKOUT } from './queries'

interface GuildStripeCheckoutSessionServiceProps {
  offerId: string
  offerName: string
  email: string
  promoCode: string | undefined
  campaign?: string | null
  medium?: string | null
  term?: string | null
  content?: string | null
  pageSlug?: string | null
  projectSlugs?: string | null
  source?: string | null
}

export const useGuildStripeCheckoutSession = ({
  offerId,
  offerName,
  email,
  campaign,
  medium,
  term,
  content,
  promoCode,
  pageSlug,
  projectSlugs,
  source,
}: GuildStripeCheckoutSessionServiceProps) => {
  const track = useSafeTrack()
  const { trackCheckoutStarted } = useEcommerceEvents()
  const lastFiredOfferIdRef = useRef<string | null>(null)

  const [createGuildStripeCheckout, { data, loading }] = useMutation<CreateGuildStripeCheckoutMutation>(
    CREATE_GUILD_STRIPE_CHECKOUT,
    {
      variables: {
        input: {
          email,
          offerId,
          promoCode,
          metadata: {
            campaign,
            medium,
            term,
            content,
            pageSlug,
            projectSlugs,
            source,
          },
          disableRedirect: true,
        },
      },
    },
  )

  useEffect(() => {
    if (hasAuthSessionCookie() && offerId && email) {
      createGuildStripeCheckout()
      if (lastFiredOfferIdRef.current === offerId) return

      lastFiredOfferIdRef.current = offerId
      track('Guild Checkout Started', { offerId, abandonedCheckoutReturnUrl: window.location.href })
    }
  }, [createGuildStripeCheckout, email, offerId, track])

  useEffect(() => {
    if (!data) return

    const value = data.createGuildStripeCheckout?.amountTotal
      ? getPriceForCurrency(
          data.createGuildStripeCheckout.amountTotal,
          data.createGuildStripeCheckout?.currency || 'usd',
        )
      : 0
    trackCheckoutStarted({
      currency: (data.createGuildStripeCheckout?.currency as MoneyCurrencyCode) ?? 'USD',
      funnel: 'guild',
      order_id: data.createGuildStripeCheckout?.sessionId,
      checkout_id: data.createGuildStripeCheckout?.sessionId,
      value,
      subscription: true,
      products: [
        {
          category: 'guild',
          name: offerName,
          price: value,
          currency: (data.createGuildStripeCheckout?.currency as MoneyCurrencyCode) ?? 'USD',
          product_id: offerId,
          quantity: 1,
          sku: offerId,
        },
      ],
    })
  }, [data, offerId, offerName, trackCheckoutStarted])

  return useMemo(() => {
    return {
      loading,
      amountTotal: data?.createGuildStripeCheckout?.amountTotal,
      clientSecret: data?.createGuildStripeCheckout?.clientSecret,
      currency: data?.createGuildStripeCheckout?.currency,
      sessionId: data?.createGuildStripeCheckout?.sessionId,
    }
  }, [data, loading])
}
